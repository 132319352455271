import * as React from "react"
// import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


const TravelServicesPage = ({ data }) => {

	const page = data.wpService;

	const subservices = data.allWpSubservice.nodes;

	return (
		<Layout title={page.title} breadcrumbs={[['Services','services'], [page.title, '#']]}>
			<Seo post={page} />
			<section className="content">
				<div id="pageContent" dangerouslySetInnerHTML={{ __html: page.content }}/>
				<ul id="subserviceList">
					{subservices.map((subservice, i) => {
						return (
							<li
								key={i}
								className="subservice"
							>
								<div className="serviceBg">
									{subservice.featuredImage !== null	?
										<GatsbyImage image={getImage(subservice.featuredImage.node.localFile)} alt={subservice.title} />
									: ''}
									<h2>{subservice.title}</h2>
									<div className="serviceContent" dangerouslySetInnerHTML={{ __html: subservice.content }}/>
								</div>
							</li>
						)
					})}
				</ul>
				<div className="serviceContact" dangerouslySetInnerHTML={{ __html: page.servicesExtra.footer }}/>
			</section>
		</Layout>
	)
}

export default TravelServicesPage

export const query = graphql`
	query TravelPageQuery {

		allWpSubservice(
			filter: {terms: {nodes: {elemMatch: {databaseId: {eq: 112}}}}}
			sort: {fields: menuOrder, order: ASC}
		) {
			nodes {
				title
				content
				featuredImage {
					node {
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									placeholder: NONE
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
		}
		wpService(databaseId: {eq: 59}) {
			content
			nodeType
			title
			uri
			servicesExtra {
				footer
			}
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
		}
	}
`;